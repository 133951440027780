import { useEffect, useState } from 'react';

function Overlay(props) {
    const messages = props.loadingMessages;
    const [message, setMessage] = useState('generating playlist...');
    const [playlistId, setPlaylistId] = useState('');
    
    useEffect(() => {
        let currentIndex = 0;
    
        const interval = setInterval(() => {
          setMessage(messages[currentIndex]);
          currentIndex = (currentIndex + 1) % messages.length;
        }, 5000);
    
        return () => clearInterval(interval);
    });

    useEffect(() => {
        setPlaylistId("https://open.spotify.com/embed/playlist/" + props.playlistId + "?utm_source=generator")
    }, [props.playlistId])

    const renderResult = () => {
      if(!props.errorResponse){
        return (
        <>
            <div className="successTitle">here you go, a brand new playlist just for you!</div>
            <iframe title='embed' style={{borderRadius: "12px", marginTop: "24px"}} frameBorder="0" src={playlistId} height="500" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
        </>
        )
      }
      return (
        <div className="successTitle">something went wrong please try again</div>
      )
    }

    return (
      <div className="overlay">
        <div className="close-button" onClick={props.onClose}>X</div>
        {
        props.isLoading ? 
          <>
            <div>
              <p className="title">{message.toLowerCase()}</p>
            </div>
            <div className="loader"></div>
            </> 
        :
        <>
          {renderResult()}
        </>
        }
      </div>
    );
  }
  
  export default Overlay;
  