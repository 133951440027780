function PromptBox(props) {
  return (
    <div className="prompt-box-container">
        <textarea placeholder="what's your mood today?" value={props.text} onChange={props.handleTextChange}></textarea>
        <button className="submit-button" onClick={props.playlistRequest}>submit</button>
    </div>
  );
}

export default PromptBox;
