import './App.css';
import PromptBox from './components/PromptBox';
import Overlay from './components/Overlay';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { MdQueueMusic } from 'react-icons/md';

function App() {
  const [text, setText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [playlistId, setPlaylistId] = useState('');
  const [errorResponse, setErrorResponse] = useState(false)
  const [currentState, setCurrentState] = useState('mainPage');
  const [loadingMessages, setLoadingMessages] = useState([]);

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  useEffect(() => {
    axios.get('https://playlistgen.azurewebsites.net/generateprompts')
      .then((response) => {
        setLoadingMessages(response.data);
      })
  }, [])

  const onClose = () => {
    setPlaylistId('');
    setCurrentState('mainPage');
  }

  const playlistRequest = () => {
    setIsLoading(true);
    setCurrentState('resultPage');
    const data = {
      prompt: text
    }

    axios.post('https://playlistgen.azurewebsites.net/generate', data)
      .then((response) => {
        setTimeout(() => {
          setPlaylistId(response.data.playListID);
          setIsLoading(false);
        }, 7000);
      })
      .catch((error) => {
        setErrorResponse(true);
        setIsLoading(false);
        console.log(error);
      });
    
  }

  return (
    <div className="App">
      {currentState === 'resultPage' && <Overlay playlistId={playlistId} isLoading={isLoading} errorResponse={errorResponse} loadingMessages={loadingMessages} onClose={onClose}/>}
      <div className='main-container'>
        <h1 className="title">fluffy sounds  <MdQueueMusic/></h1>
        <p className="subtitle">your very own playlist <br/> generator based on whatever <br/> mood you're in right now</p>
        <PromptBox text={text} handleTextChange={handleTextChange} playlistRequest={playlistRequest}/>
      </div>
    </div>
  );
}

export default App;
